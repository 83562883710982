import {Switch,Text,Group,Button, em } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from '@mantine/hooks';

function Banner({customThemeStyle, populateOptions, selectedOptions}): JSX.Element {
  const [additionalDetailsOptions, setAdditionalDetailsOptions] = useState<string[]>([]);
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  useEffect(() => {
    if(additionalDetailsOptions.length == 0) {
        const selectedAdditionalOptionals = customThemeStyle.consentOptions.filter(opt => opt.checked).map(opt => opt.label);
        setAdditionalDetailsOptions(selectedAdditionalOptionals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePopulateOptions = (optionValues) => {
    setAdditionalDetailsOptions(optionValues);
    populateOptions(optionValues);
  };

  return (
    <React.Fragment>
      <>
        <Text fw={700} c={customThemeStyle.textColor}>We Value your privacy</Text>
        <Text size="xs" c={customThemeStyle.textColor}> we use cookies to enhance your browsing experience, </Text>
        <Text size="xs" c={customThemeStyle.textColor}> serve personalized ads or content and analyze your traffic </Text>
        <Text size="xs" c={customThemeStyle.textColor}> By clicking "Accept All", you consent to our use of cookies. </Text>
        <Switch.Group  value={selectedOptions} onChange={handlePopulateOptions}>
           <Group mt="md">
            {customThemeStyle.consentOptions.map((option, index) => (
                <Group key={index}>
                    <Switch size="xs" value={option.label} color={customThemeStyle.highlight} checked={customThemeStyle.checked}  />
                    <Text size="xs" c={customThemeStyle.textColor}>{option.label}</Text>
                </Group>
            ))}
           </Group>
        </Switch.Group>
        <Group mt="md">
          <Button size="xs" color={customThemeStyle.buttonCustomizeBackGround} variant={customThemeStyle.buttonCustomizeVariant}>
            <Text size={isMobile?"8":"xs"} c={customThemeStyle.buttonCustomizeTextColor}>Customize</Text>
          </Button>
          <Button size="xs" color={customThemeStyle.buttonRejectBackGround} variant={customThemeStyle.buttonRejectVariant}>
            <Text size={isMobile?"8":"xs"} c={customThemeStyle.buttonRejectTextColor}>Reject All</Text>
          </Button>
          <Button size="xs" color={customThemeStyle.buttonAcceptBackGround} variant={customThemeStyle.buttonAcceptVariant}>
            <Text size={isMobile?"8":"xs"} c={customThemeStyle.buttonAcceptTextColor}>Accept</Text>
          </Button>
        </Group>
      </>
    </React.Fragment>
  );
}

export { Banner };
