import {Box,Group,Text,Title,Container} from '@mantine/core';
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ConsentService } from "../../services/consentService";
import { MantineReactTable } from 'mantine-react-table';
import { ConfigContext } from '../../Context/configContext';
import { useTitle } from '../hooks/useTitle';

function ConsentRecordPage(): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [domainId, setDomainId] = useState<any | null>(localStorage.getItem('activeDomainId'));
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const consentService = useMemo(() => new ConsentService(config!), [config]);

  useTitle("Consent Records");

  useEffect(() => {
    if(!domainId) {
     setDomainId(localStorage.getItem('activeDomainId'));
    }

    if(domainId) {
         setLoading(true);
         const response = consentService.getDomainScanDataById(domainId);
            response.then((res) => {
            if(res?.data){
                setData(res?.data?.allCookies);
            }
            setLoading(false);
         });
    }
  }, [consentService, domainId]);

 const CookieExpiration = (timestamp: number) => {
     const date = new Date(timestamp * 1000); // Convert to milliseconds
     const day = String(date.getDate()).padStart(2, '0'); // Get day and ensure two digits
     const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-indexed, so add 1) and ensure two digits
     const year = String(date.getFullYear()); // Get last two digits of the year
     const hours = String(date.getHours()).padStart(2, '0'); // Get hours and ensure two digits
     const minutes = String(date.getMinutes()).padStart(2, '0'); // Get minutes and ensure two digits

     return `${day}-${month}-${year} ${hours}:${minutes}`; // Format as DD MM YY hh:mm
 };

 const columns = [
      { accessor: 'name', header: 'Name',
          Cell: ({ row }) => (
           <Text size="sm">
               {row.original.name}
           </Text>
          ),
      },
      { accessor: 'expiry', header: 'Expiry',
        Cell: ({ row }) => (
            <Text size="sm">
                {CookieExpiration(row.original.expiry)} {/* Pass the timestamp */}
            </Text>
        ),
      },
      { accessor: 'provider', header: 'Provider',
        Cell: ({ row }) => (
          <Text size="sm">
              {row.original.provider}
          </Text>
        ),
      },
      { accessor: 'category', header: 'Category',
       Cell: ({ row }) => (
         <Text size="sm">
              {row.original.category}
         </Text>
       ),
      },
  ];

  return (
    <React.Fragment>
      <>
         <Container fluid>
            <Group justify="space-between" mb={20}>
               <div>
                    <Title mt="lg" order={2}>Cookies Report</Title>
                </div>
            </Group>
            <Box>
                {data.length > 0 && (
                    <MantineReactTable
                        columns={columns}
                        data={data}
                        state={{ isLoading: loading }}
                        enablePagination
                        initialState={{ pagination: { pageSize: 10, pageIndex: 0 } }}
                        columnResizeMode="onChange"
                        mantineTableHeadCellProps={{
                            style: {
                              backgroundColor: '#f9f9f9', // Changed header background color
                              color: '#000',               // Changed header text color
                              fontWeight: 600,
                              borderBottom: '2px solid #0056b3', // Darker border for header
                              padding: '12px',             // Added padding for header cells
                            },
                          }}
                          mantineTableBodyCellProps={{
                            style: {
                              padding: '12px',
                              borderBottom: '1px solid #eee',
                              transition: 'background-color 0.3s', // Smooth transition for hover effect
                            },
                          }}
                    />
                 )}
            </Box>
         </Container>
      </>
    </React.Fragment>
  );
}

export { ConsentRecordPage }