import { AppShell } from "@mantine/core";
import React, { useState } from "react";
import { DashboardPage } from "../../Components/account/DashboardPage";
import { AppHeader } from "../../Components/app-header/AppHeader";
import { AppSideBar } from "../../Components/app-side-bar/AppSideBar";
import { useAuth } from "../../Context/useAuth";
import { useDisclosure } from '@mantine/hooks';

function Dashboard(): JSX.Element {
  const { user } = useAuth();
  const [currentConsentData, setCurrentConsentData] = useState<any | null>(null);
  const [opened, { toggle }] = useDisclosure();

  const handleActiveConsentData = (consentData) => {
    setCurrentConsentData(consentData);
  };

  return (
    <React.Fragment>
      <>
        <AppShell
          padding="md" style={{background:'#f2f4fa'}}
          header={{ height: 60 }}
          navbar={{
            width: 300,
            breakpoint: "sm",
            collapsed: { mobile: !opened },
          }}
          >
          <AppShell.Header>
            <AppHeader opened={opened} toggle={toggle} user={user} activeConsentData={handleActiveConsentData} />
          </AppShell.Header>
          <AppShell.Navbar>
            <AppSideBar name="Home" />
          </AppShell.Navbar>
          <AppShell.Main>
                <DashboardPage user={user} currentConsentData={currentConsentData} />
          </AppShell.Main>
        </AppShell>
      </>
    </React.Fragment>
  );
}

export { Dashboard };
