import { AppShell } from "@mantine/core";
import React from "react";
import { CookieReportPage } from "../../Components/account/CookieReportPage";

function CookieReport(): JSX.Element {

  return (
    <React.Fragment>
      <>
        <AppShell
          padding="md"
          style={{ background: "#f2f4fa" }}
        >
          <AppShell.Main>
            <CookieReportPage />
          </AppShell.Main>
        </AppShell>
      </>
    </React.Fragment>
  );
}

export { CookieReport };
