import {Popover,Group,ColorSwatch,Text,Tooltip,ColorPicker} from "@mantine/core";
import React, { useState } from "react";
import {IconInfoCircle} from "@tabler/icons-react";

function ButtonTheme(props): JSX.Element {
 const [colorValue, setColorValue] = useState('rgba(47, 119, 150, 0.7)');

  const handleCustomStyles = (value) => {
      setColorValue(value);
      props.customStyles(value, props.type, props.label);
  };

  return (
    <React.Fragment>
      <>
        <Popover trapFocus position="bottom" withArrow shadow="md">
           <Popover.Target>
                <Group mt="xs">
                    <ColorSwatch color={props.background} p="10" />
                    <Text size="xs">{props.label}</Text>
                    {props.description != "" && (
                     <Tooltip label={props.description}>
                        <IconInfoCircle size="14"/>
                     </Tooltip>
                    )}
                </Group>
           </Popover.Target>
           <Popover.Dropdown>
             <ColorPicker value={colorValue} onChange={handleCustomStyles} />
           </Popover.Dropdown>
         </Popover>
      </>
    </React.Fragment>
  );
}

export { ButtonTheme };
