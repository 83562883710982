import axios from 'axios';
import { getAuthorizationHeader } from "../utils/getAuthorizationHeader";
import { consentBannerResponse } from "../Models/consentBannerResponse";
import { consentBannerConfig } from "../Models/consentBannerConfig";
import { FileWithPath } from "@mantine/dropzone";
import { Config } from '../utils/types';

export class ConsentService {
  private config: Config;

  constructor(config: Config) {
    this.config = config;
  }

  public async saveConsent (data: consentBannerConfig): Promise<consentBannerResponse>  {
    try {
      const response = await axios.post(this.config.apiBaseUrl + "/consent/config/",
          data,
          {headers: getAuthorizationHeader()}
      );
      return response.data;
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  public async scanDomain(domainId: string,) {
    try {
      const response = await axios.post(`${this.config.scanApiBaseUrl}/scan/domain/${domainId}`,
               {headers: getAuthorizationHeader()});
      return response.data;
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  public async saveScanFrequencyDomain(domainId: string,data: any) {
    try {
      const response = await axios.put(`${this.config.apiBaseUrl}/domain/frequency/${domainId}`,
       data,
       {headers: getAuthorizationHeader()}
      );
      return response.data;
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  public async updateConsent (domainId: string, data: consentBannerConfig): Promise<consentBannerResponse> {
    const url = this.config.apiBaseUrl + "/consent/config/" + domainId;
    try {
      const response = await axios.put(`${url}`,
          data,
          {headers: getAuthorizationHeader()}
      );
      return response.data;
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  public async deleteDomain(domainId: string) {
    const url = this.config.apiBaseUrl + "/consent/config/" + domainId;
    try {
      const response = await axios.delete(`${url}`,
          {headers: getAuthorizationHeader()}
      );
      return response.data;
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  public getConsents() {
    try {
      return axios.get(this.config.apiBaseUrl + "/consent/config/",
          {headers: getAuthorizationHeader()}
      );
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  public getDomains(): any {
    try {
      return axios.get(this.config.apiBaseUrl + "/consent/config/",
          {headers: getAuthorizationHeader()}
      );
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  public getConsentById(domainId:string) {
    const url = this.config.apiBaseUrl + "/consent/config/" + domainId;
    try {
      return axios.get(`${url}`,
          {headers: getAuthorizationHeader()}
      );
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  public getDomainScanDataById(domainId:string) {
    const url = `${this.config.apiBaseUrl}/domain/report/${domainId}`;
    try {
      return axios.get(`${url}`,
          {headers: getAuthorizationHeader()}
      );
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  public async uploadClientLogo(domainId:string, files: FileWithPath[]) {
    console.log(domainId);
    const url = this.config.apiBaseUrl + "/consent/config/uploadFile/" + domainId;
    try {
      const formData = new FormData();
      formData.append('file', files[0]);
      const response = await axios.put(url,
          formData,
          { headers:
              {   'Authorization': `Bearer ${localStorage.getItem("token") ||""}`,
                  'Content-Type': 'multipart/form-data'
              }
          }
      );

      return response.data;
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  public async downloadCSVFile(domainId) {
    const apiUrl = `${this.config.apiBaseUrl}/domain/url/${domainId}`;

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch CSV data');
      }

      const blob = await response.blob(); // Convert response to blob (binary large object)
      return blob; // Return the blob for further processing in the component
    } catch (error) {
      console.error('Error fetching CSV file:', error);
      throw error; // Re-throw error to handle it in the component
    }
  }
};
