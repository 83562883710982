import React, { useState, useEffect, useRef, useContext } from "react";
import { IconCircleCheckFilled, IconCalendarClock } from "@tabler/icons-react";
import { Flex, Loader, LoadingOverlay, Mark, Avatar, Container, Title, Grid, Card, Text, Button, Table, Group, Center, Modal, SimpleGrid, Paper } from '@mantine/core';
import { ConsentService } from "../../services/consentService";
import { ConsentOptionsConfig } from "../../Models/consentOptionsConfig";
import { showNotification } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { ConfigContext } from "../../Context/configContext";
import classes from "./tables.module.css";

const scanSchedule : ConsentOptionsConfig[] =  [{checked: true,label: "Daily"},{checked: false,label: "Weekly"},{checked: false,label: 'Monthly'},{checked: false,label: "3 Months"}];

function PrivacyAudits(): JSX.Element {
  const initialized = useRef(false);
  const { config } = useContext(ConfigContext);
  const [domainId] = useState<any | null>(localStorage.getItem('activeDomainId'));
  const [domainName ] = useState<any | null>(localStorage.getItem('activeDomain'));
  const [necessaryCount, setNecessaryCount] = useState(0);
  const [preferencesCount, setPreferencesCount] = useState(0);
  const [statisticsCount, setStatisticsCount] = useState(0);
  const [marketingCount, setMarketingCount] = useState(0);
  const [unClassifiedCount, setUnClassifiedCount] = useState(0);
  const [scanHistory, setScanHistory] = useState<any[]>([]);
  const [opened, setOpened] = useState(false);
  const [frequency, setFrequency] = useState('Daily');
  const [loading, setLoading] = useState(false);
  const [lastScan, setLastScan] = useState<any>('Not Started Yet');
  const [nextScan, setNextScan] = useState('Not Started Yet');
  const [isProcessed, setIsProcessed] = useState('NOT_STARTED');
  const [totalScannedPages, setTotalScannedPages] = useState(0);

  const consentService = new ConsentService(config!);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      getDomainScanDataById();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized]);

  const saveFrequency = async () => {
    if (domainId) {
        setLoading(true);

        const data = { frequency: frequency };
        try {
            await consentService.saveScanFrequencyDomain(domainId, data);
            setOpened(false);
            showNotification({ color: "green", message: "Scan Schedule updated!" });
            getDomainScanDataById(); // Call the function to refresh the data
        } catch (error) {
            console.error('Error saving scan frequency:', error);
            showNotification({ color: "red", message: "Failed to update scan schedule." });
        } finally {
            setLoading(false); 
        }
    }
};


  const scanDomain = () => {
      if(domainId) {
        consentService.scanDomain(domainId);
        setIsProcessed('PENDING');
        showNotification({ color: "green", message: "Request is submitted for scan the domain" });
      }
  };

  const handleDownload = async () => {
      setLoading(true);

      try {
        const blob = await consentService.downloadCSVFile(domainId); // Call the service to get the blob

        // Process the blob and trigger the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'cookies-data.csv'; // File name for the CSV
        document.body.appendChild(a);
        a.click();
        a.remove();

        // Release the URL object to free up memory
        window.URL.revokeObjectURL(url);
      } catch (error) {
        showNotification({ color: "red", message: "Error downloading the CSV file." });
      } finally {
        setLoading(false);
      }
  };

  const getDomainScanDataById = () => {
    if(domainId) {
         const response = consentService.getDomainScanDataById(domainId);
         response.then((res) => {
            if(res?.data){
                setNecessaryCount(res?.data?.latestScanResult?.necessaryCookies?res?.data?.latestScanResult?.necessaryCookies:0);
                setPreferencesCount(res?.data?.latestScanResult?.preferenceCookies || 0);
                setStatisticsCount(res?.data?.latestScanResult?.statisticsCookies || 0);
                setMarketingCount(res?.data?.latestScanResult?.marketingCookies || 0);
                setUnClassifiedCount(res?.data?.latestScanResult?.unclassifiedCookies || 0);
                setScanHistory(res?.data?.allPreviousScanResult.length > 0? res?.data?.allPreviousScanResult:[res?.data?.latestScanResult]);
                const lastScanTimestamp = res?.data?.lastScan?.$date?.$numberLong;
                if (lastScanTimestamp) {
                  const theDate = new Date(parseInt(lastScanTimestamp));
                  setLastScan(formatDate(theDate)); // Correctly formatted scan date
                } else {
                  setLastScan("No scan date available");
                }
                
                const nextScanTimestamp = res?.data?.nextScan?.$date?.$numberLong;
                if (nextScanTimestamp) {
                  const theDate = new Date(parseInt(nextScanTimestamp));
                  setNextScan(formatDate(theDate)); // Correctly formatted scan date
                } else {
                  setNextScan("No scan date available");
                }

                if(res?.data?.status) {
                    if(res?.data?.status === 'Progress') {
                        setIsProcessed('PENDING');
                    } else {
                        setIsProcessed(res?.data?.status);
                    }
                }

                if(res?.data?.totalPages) {
                    setTotalScannedPages(res?.data?.totalPages);
                }
            }
         });
    }
  };

  const formatDate = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zeros if necessary
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const navigate = useNavigate();

  return (
   <React.Fragment>
      {loading && (
          <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
     <>
        <Container fluid>
          <Card bg="#F4F6FB" p="0" mb="md" mt="md">
              <Group justify="space-between">
                <Title order={2} mb="sm">Privacy Audits</Title>
              </Group>
          </Card>
          <Grid>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Card shadow="sm" p="lg" h={290}>
                <Card.Section p="md" withBorder>
                    <Group justify="space-between">
                        {domainName && (
                            <Text size="sm" fw={500}>{domainName}</Text>
                        )}
                        {isProcessed != 'PENDING' && (
                            <Button onClick={() => scanDomain()} variant="filled" size="xs">Launch Next Audit</Button>
                        )}

                         {isProcessed == 'PENDING' && (
                          <Flex>
                              <Loader color="blue" size="xs" />
                            <Text size="sm" c="orange" fw={500}> &nbsp; Audit In Progress</Text>
                            </Flex>
                         )}
                    </Group>
                </Card.Section>
                <Card.Section p="lg">
                    <Title order={6} fw="500">Latest Audit</Title>
                        {lastScan && (
                                <Text size="sm">{lastScan}</Text>
                        )}
                    <Title order={6}  fw="500" mt="md">Next Audit</Title>
                    <Text size="sm">
                        {nextScan && (
                            <span>{nextScan} &nbsp;</span>
                        )}
                        <span style={{color:'blue', cursor: 'pointer'}} onClick={() => setOpened(true)}>Schedule</span>
                    </Text>
                    <Title order={6}  fw="500" mt="md">Scanned pages</Title>
                    <Text size="sm" c="blue" style={{cursor:'pointer'}} onClick={handleDownload}>{totalScannedPages} pages (Download CSV)</Text>
                </Card.Section>
              </Card>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Card shadow="sm" p={20} h={290}>
                <Card.Section p="lg" withBorder>
                    <Text size="sm" fw={500}>Trackers</Text>
                </Card.Section>
                <Card.Section p="lg" withBorder>
                    <Grid>
                      <Grid.Col span={{ base: 12, md: 4, lg: 4 }}><Text size="sm">Necessary :</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 6, lg: 6 }}><Text size="sm">{necessaryCount}</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 4, lg: 4 }}><Text size="sm">Preference :</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 6, lg: 6 }}><Text size="sm">{preferencesCount}</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 4, lg: 4 }}><Text size="sm">Statistics :</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 6, lg: 6 }}><Text size="sm">{statisticsCount}</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 4, lg: 4 }}><Text size="sm">Marketing :</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 6, lg: 6 }}><Text size="sm">{marketingCount}</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 4, lg: 4 }}><Text size="sm">Unclassified :</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 6, lg: 6 }}><Text size="sm">{unClassifiedCount}</Text></Grid.Col>
                    </Grid>
                </Card.Section>
              </Card>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
              <Card shadow="sm" padding="lg">
                <Card.Section p="md">
                    <Text size="lg" fw={500}>Audit History</Text>
                </Card.Section>
                <Table  horizontalSpacing="md" verticalSpacing="xs" miw={700} layout="fixed">
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Audit Time</Table.Th>
                      <Table.Th>Status</Table.Th>
                      <Table.Th>Pages scanned</Table.Th>
                      <Table.Th># Trackers</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {scanHistory.map((scan, index) => (
                      <Table.Tr key={index} 
                        className={classes.clickableRow}
                        onClick={(event) => {
                          if (event.metaKey || event.ctrlKey){
                            const win = window.open(`/account/consentRecords`, "_blank");
                            win?.focus();
                          } else {
                            navigate(
                              `/account/consentRecords`,
                            );
                          }
                        }} >
                        <Table.Td> {formatDate(new Date(parseInt(scan?.scannedOn?.$date?.$numberLong)))}</Table.Td>
                        <Table.Td>
                         <Text size="xs">
                               <Mark p={5} style={{"textTransform": "capitalize"}}>{scan.status}</Mark>
                         </Text>
                        </Table.Td>
                        <Table.Td>{scan.pages}</Table.Td>
                        <Table.Td>{scan.cookiesCount}</Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table>
              </Card>
            </Grid.Col>
          </Grid>
          <Modal withCloseButton={false} opened={opened} onClose={() => setOpened(false)} size="md" centered>
              <Center>
                <Avatar color="blue" radius="xl" bg="blue">
                   <IconCalendarClock color="white"  />
                </Avatar>
              </Center>
              <Center>
                <Title order={2} ta="center" mt="md">Schedule Automatic Scans</Title>
              </Center>
              <Center mt="sm">
                <Text size="xs" ta="center" pl="30" pr="30">
                    Your website scan will run automatically with a frequency set below. You can also initiate a scan here.
                </Text>
              </Center>
              <Center mt="md">
                   <SimpleGrid cols={4}>
                      {scanSchedule.map((sc, index) => (
                       <Paper key={index} shadow="0px" pt="xs" pb="xs" pl="xs" pr="xs" withBorder style={{ position: "relative",cursor:"pointer" }} onClick={(event) => {setFrequency(sc.label === '3 Months'?'ThreeMonths':sc.label)}}>
                           <Text size="xs">{sc.label}</Text>
                           {frequency == (sc.label === '3 Months'?'ThreeMonths':sc.label) && (
                              <IconCircleCheckFilled className="primary"
                                  size="1rem"
                                  stroke={3}
                                  style={{ left: "-8px", top: "-7px", position: "absolute" }}
                              />
                           )}
                        </Paper>
                      ))}
                   </SimpleGrid>
              </Center>
              <Center mt="xl">
                <Button w="200" onClick={() => saveFrequency()}>Save</Button>
              </Center>
          </Modal>
        </Container>
    </>
   </React.Fragment>
  );
};

export { PrivacyAudits };
