import { Button, Center, Group, Stack, TextInput, ThemeIcon, Title } from "@mantine/core";
import { IconWorld } from "@tabler/icons-react";
import { useForm } from '@mantine/form';

function AddDomain({ domainName, consentData}) {
    const urlRegex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');

    const form = useForm({
      initialValues: {url: domainName || ''},
      validate: {
        url: (value) => !value ? 'Domain URL is required':(urlRegex.test(value) ? null : 'Invalid domain URL'),
      },
    });

    const handleDomainUrl = (values) => {
       consentData(values.url, "domainUrl", "33%");
    };

    return (
    <form onSubmit={form.onSubmit(handleDomainUrl)} style={{ width: '100%' }}>
        <Stack p="xl" align="center">
            <Group justify="center" style={{ width: '100%' }}>
                <Title order={2} fw={500}>Add your domain</Title>
            </Group>
            
            <Center>
            <ThemeIcon radius={100} size={90} color="rgba(224, 224, 224, 1)">
                <IconWorld color="black" style={{ width: '70%', height: '70%' }} />
            </ThemeIcon>  
            </Center>
            
            <TextInput mt="lg"
                placeholder="www.example.com"
                {...form.getInputProps('url')}
                style={{ width: '100%' }}
            />
    
            <Button type="submit" fullWidth>
                Next
            </Button>
        </Stack>
    </form>
    );
  }

  export { AddDomain };