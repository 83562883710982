import axios from 'axios';
import { getAuthorizationHeader } from "../utils/getAuthorizationHeader";
import { Profile } from "../Models/profile";
import { handleError } from "../Helpers/ErrorHandler";
import { UpdatePassword } from "../Models/updatePassword";
import { Config } from '../utils/types';

export class UserService {
  private config: Config;

  constructor(config: Config) {
    this.config = config;
  }

  public async saveOrUpdateProfile(data: Profile) {
    try {
      const response = await axios.put(this.config.apiBaseUrl + "/user/profile",
          data,
          {headers: getAuthorizationHeader()}
      );
      return response.data;
    } catch (error) {
      handleError(error);
    }
  }

  public async updatePassword(data: UpdatePassword) {
    try {
      const response = await axios.put(this.config.apiBaseUrl + "/user/account",
          data,
          {headers: getAuthorizationHeader()}
      );
      return response.data;
    } catch (error) {
       handleError(error);
    }
  }

  public async deleteAccount() {
    try {
      const response = await axios.delete(this.config.apiBaseUrl + "/user/account",
          {headers: getAuthorizationHeader()}
      );
      return response.data;
    } catch (error) {
       handleError(error);
    }
  }

  public async getProfile() {
    try {
      const response = await axios.get(this.config.apiBaseUrl + "/user/profile",
          {headers: getAuthorizationHeader()}
      );
      return response.data;
    } catch (error) {
       handleError(error);
    }
  }
}
