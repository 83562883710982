
import { AppShell } from "@mantine/core";
import React from "react";
import { AnalyticsPage } from "../../Components/account/AnalyticsPage";
import { AppHeader } from "../../Components/app-header/AppHeader";
import { AppSideBar } from "../../Components/app-side-bar/AppSideBar";
import { useAuth } from "../../Context/useAuth";
import { useDisclosure } from '@mantine/hooks';

function Analytics(): JSX.Element {
  const { user } = useAuth();
  const [opened, { toggle }] = useDisclosure();

  return (
    <React.Fragment>
      <>
        <AppShell
          padding="md"
          style={{ background: "#f2f4fa" }}
          header={{ height: 60 }}
          navbar={{
            width: 300,
            breakpoint: "sm",
             collapsed: { mobile: !opened },
          }}
        >
          <AppShell.Header>
            <AppHeader opened={opened} toggle={toggle} user={user} />
          </AppShell.Header>
          <AppShell.Navbar>
            <AppSideBar name="Analytics" />
          </AppShell.Navbar>
          <AppShell.Main>
            <AnalyticsPage />
          </AppShell.Main>
        </AppShell>
      </>
    </React.Fragment>
  );
}

export { Analytics };
