import {
  Text,
  Container,
  Space,
  Card,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { Step5 } from "./consent/Step5";
import { ConsentService } from "../../services/consentService";
import { consentBannerConfig } from "../../Models/consentBannerConfig";
import { showNotification } from '@mantine/notifications';
import { ThemeDetails } from "../../Models/themeDetails";
import { ConsentOptionsConfig } from "../../Models/consentOptionsConfig";
import { ConfigContext } from "../../Context/configContext";
import { useTitle } from "../hooks/useTitle";

const consentOptionsData : ConsentOptionsConfig[] =  [{checked: true,label: "Necessary"},{checked: false,label: "Preferences"},{checked: false,label: "Statistics"},{checked: false,label: "Marketing"}];
const themesConfig: ThemeDetails[] = [
    {background:"#fff",type:"Light",buttonStyle:"Mixed",textColor:"#000",highlight:"#f76707",buttonCustomizeBackGround:"#f76707",buttonCustomizeTextColor:"white",buttonCustomizeVariant:"filled",buttonCustomizeBorder:"#f76707",buttonRejectBackGround:"#f76707",buttonRejectTextColor:"white",buttonRejectVariant:"filled",buttonRejectBorder:"#f76707",buttonAcceptBackGround:"#f76707",buttonAcceptTextColor:"white",buttonAcceptVariant:"filled",buttonAcceptBorder:"#f76707",consentOptions:consentOptionsData},
];

const tempObj = {language:'en',domainId:'',domainUrl:'',geoLocations:[],consentExpire:'',styles:'', locations:[]};
function ConsentBannerPage(props): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [domainId, setDomainId] = useState<any | null>(localStorage.getItem('activeDomainId'));
  const [domainName, setDomainName] = useState<any | null>(localStorage.getItem('activeDomain'));
  const [consentConfigRequest, setConsentConfigRequest] = useState<consentBannerConfig>(tempObj);
  const [configuredTheme, setConfiguredTheme] = useState<any | null>(null);

  const consentService = new ConsentService(config!);

  useTitle("Manage Consent Banner");

  useEffect(() => {
      if(domainId) {
         const response = consentService.getConsentById(domainId);
         response.then((res) => {
            if(res?.data){
                setConsentConfigRequest(res?.data);

                if(res?.data?.theme) {
                   setConfiguredTheme(JSON.parse(res?.data?.theme));
                } else {
                    setConfiguredTheme(themesConfig[0]);
                }

                localStorage.setItem("activeDomain", res?.data?.domainUrl);
            }
         });
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const publishChanges = (stepNo) => {
    showNotification({ color: "green", message: "Update successfully!" });
  };

  const handleConsentData = (fieldValue, fieldName) => {

       if(fieldName == 'styles') {
           consentConfigRequest[fieldName] = JSON.stringify(fieldValue);
       }

       consentConfigRequest['language'] = fieldValue.language;

       if(consentConfigRequest.locations) {
            consentConfigRequest['geoLocations'] = consentConfigRequest.locations;
       }

       const response = consentService.updateConsent(domainId, consentConfigRequest);
       response.then((res) => {
           if(res) {
                setDomainId(res?.id);
                setDomainName(res?.domainUrl);
                showNotification({ color: "green", message: "Update successfully!" });
           }
       });
  };

  return (
    <React.Fragment>
      <>
        <Container fluid>
           <Space h="lg" />
           {(domainId && configuredTheme) && (
              <div>
                <Card shadow="sm" p="xl"  mb="xl">
                    <Text fw={700} mb={10}>{consentConfigRequest.domainUrl}</Text>
                    <Text size="xs">
                        Set up the user interface of your consent banner, cookie declaration and privacy trigger.
                    </Text>
                </Card>
                <Step5 forward={publishChanges} backward={null} screenSize={null} consentData={handleConsentData} consentConfigData={configuredTheme} domainId={domainId} domainName={domainName}/>
              </div>
           )}
           {!domainId && (
             <div>
               <Card shadow="sm" p="xl"  mb="xl">
                   <Text ta="center" size="xs" c="red" fw="700">
                        No Consent Found ! Please configure or Select Consent first
                   </Text>
               </Card>
             </div>
           )}
        </Container>
      </>
    </React.Fragment>
  );
}

export { ConsentBannerPage };
