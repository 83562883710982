import {
  Text,
  Container,
  Space,
  Title,
  Group,
  Card,
  Tabs,
  SimpleGrid,
  Table,
} from "@mantine/core";
import { IconDots } from "@tabler/icons-react";
import React from "react";
import { ProfileInformation } from "./ProfileInformation";
import { ChangePassword } from "./ChangePassword";
import { DeleteAccount } from "./DeleteAccount";

function ProfilePage(): JSX.Element {
  const elements = [
    {
      siteUrl: "example.com",
      symbol: "C",
      name: "Example",
      createdDate: "2023/11/01",
      plan: "Free",
      nextRenewal: "-",
    },
  ];

  const rows = elements.map((element) => (
    <Table.Tr key={element.name}>
      <Table.Td>{element.siteUrl}</Table.Td>
      <Table.Td>{element.name}</Table.Td>
      <Table.Td>{element.createdDate}</Table.Td>
      <Table.Td>{element.plan}</Table.Td>
      <Table.Td>{element.nextRenewal}</Table.Td>
      <Table.Td>
        <IconDots />
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <React.Fragment>
      <>
        <Container fluid>
          <Space h="lg" />
          <Title order={2}>Profile</Title>
          <Space h="lg" />
          <Card withBorder shadow="sm" radius="md">
            <Card.Section withBorder inheritPadding mt="sm" pb="md">
              <Tabs defaultValue="account">
                <Tabs.List>
                  <Tabs.Tab value="account">Account</Tabs.Tab>
                  <Tabs.Tab value="changePassword">Change Password</Tabs.Tab>
                  <Tabs.Tab value="subscription">Subscription</Tabs.Tab>
                  <Tabs.Tab value="invoices">Invoices</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="account">
                    <ProfileInformation />
                </Tabs.Panel>

                <Tabs.Panel value="changePassword">
                    <ChangePassword />
                </Tabs.Panel>

                <Tabs.Panel value="subscription">
                  <Card shadow="0">
                    <Card.Section withBorder inheritPadding py="xs">
                      <Group>
                        <Text size="sm" mt={10} fw={600}>
                          Your Subscriptions
                        </Text>
                      </Group>
                      <SimpleGrid cols={1} mt={10} mb={20}>
                        <Table mt={20}>
                          <Table.Thead>
                            <Table.Tr>
                              <Table.Th>Site URL</Table.Th>
                              <Table.Th>Site name</Table.Th>
                              <Table.Th>Created date</Table.Th>
                              <Table.Th>Plan</Table.Th>
                              <Table.Th>Next Renewal</Table.Th>
                              <Table.Th></Table.Th>
                            </Table.Tr>
                          </Table.Thead>
                          <Table.Tbody>{rows}</Table.Tbody>
                        </Table>
                      </SimpleGrid>
                    </Card.Section>
                  </Card>
                </Tabs.Panel>

                <Tabs.Panel value="invoices">
                  <Card shadow="0">
                    <Card.Section withBorder inheritPadding py="xs">
                      <Group>
                        <Text size="sm" mt={10} fw={600}>
                          Invoices
                        </Text>
                      </Group>
                      <SimpleGrid cols={1} mt={10} mb={20}>
                        <Table mt={20}>
                          <Table.Thead>
                            <Table.Tr>
                              <Table.Th>Invoice Number</Table.Th>
                              <Table.Th>Created date</Table.Th>
                              <Table.Th>Action</Table.Th>
                            </Table.Tr>
                          </Table.Thead>
                          <Table.Tbody></Table.Tbody>
                        </Table>
                      </SimpleGrid>
                    </Card.Section>
                  </Card>
                </Tabs.Panel>
              </Tabs>
            </Card.Section>
          </Card>
          <DeleteAccount />
        </Container>
      </>
    </React.Fragment>
  );
}

export { ProfilePage };
